import React, { createContext, useState } from 'react';

// Create the context

export const GlobalStateContext = createContext();

// Create a provider component
export const GlobalStateProvider = ({ children }) => {

  const [userGuid, setUserGuid] = useState('');
  const [isLoggedIn, setIsloggedIn] = useState(false)
  const [apiHostUrl, setApiHostUrl] = useState('https://siamapi.kiwijones.com')

  return (
    <GlobalStateContext.Provider value={{ isLoggedIn, setIsloggedIn,userGuid, setUserGuid, apiHostUrl, setApiHostUrl }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

//const apiUrl = "HTTPS://siamapi.kiwijones.com"
//const {apiHostUrl, setApiHostUrl} = useContext(GlobalStateContext);

export const SendInternalEmail = (apiHost, email, subject, message) => {
  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");

  const raw_email = JSON.stringify({
    apiKey: "<string>",
    messageFrom: "<string>",
    welcomeMessage: "<string>",
    first: "<string>",
    from: "<string>",
    to: email,
    subject: subject,
    message: message,
  });

  const requestOptions_email = {
    method: "POST",
    headers: myHeaders,
    body: raw_email,
    redirect: "follow",
  };

  var url = apiHost + "/Communication/Email_Via_ETL"
  fetch(
    url, requestOptions_email
  )
    .then((response) => response.text())
    .then((result) => {
      console.log("email sent");
      return "Email Sent";
      console.log(result);
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const SendWelcomeEmail = (apiHost,email) => {

  let subscriptionId = "ff711f19-476b-4938-8883-6bdd5ed229cd";

  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  var url = apiHost + "/Restaurant/GetSubscription?subscriptionId=" + subscriptionId + ""

  fetch(
    url,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {


    //   console.log("===============================================");

    //   console.log(result);

       let data = JSON.parse(result);
       
    //    console.log(data);

    //    console.log(data.welcomeMessage);


    //   console.log("===============================================");


      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
    
      const raw_email = JSON.stringify({
        apiKey: "<string>",
        messageFrom: "<string>",
        welcomeMessage: "<string>",
        first: "<string>",
        from: "<string>",
        to: email,
        subject: data.name,
    
        message: data.welcomeMessage,
      });
    
      const requestOptions_email = {
        method: "POST",
        headers: myHeaders,
        body: raw_email,
        redirect: "follow",
      };
      var url = apiHost + "/Communication/Email_Via_ETL"
      fetch(
        url,
        requestOptions_email
      )
        .then((response) => response.text())
        .then((result) => {
          console.log("email sent");
          return "Email Sent";
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
      
    })

    .catch((error) => {
      console.error(error);
    });

  
};


// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer>

      <hr/>
      <p>Siam Thai</p>
    </footer>
  );
}

export default Footer;

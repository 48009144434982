import Navbar from "./navbar";
import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Routes,
  Switch
} from "react-router-dom";
import Home from "./pages/Home";
import Location from "./pages/Location";
import Register from "./pages/Register";
import CheckPoints from "./pages/Points";
import MyDetails from "./pages/MyDetails";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalStateProvider } from "./pages/GlobalStateContext";
import { ToastContainer } from 'react-toastify';
import Profile from "./pages/Profile";

import "./App.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // const [posts] = useState([
  //   { id: 1, title: 'First Post', content: 'Content of the first post.', date: '2022-01-30' },
  //   { id: 2, title: 'Second Post', content: 'Content of the second post.', date: '2022-01-31' },
  //   // Add more posts as needed
  // ]);
  // console.log(posts)
 
  return (
    <>
      <GlobalStateProvider>
        <Router>
          <Navbar />
          <Routes>
           
            <Route exact path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/check" element={<CheckPoints />} />
            <Route path="/mydetails" element={<MyDetails />} />
            
            <Route path="/login" element={<Login />} />

            {/* <Route path="/login" render={() => <Login onLogin={handleLogin} />} /> */}

            <Route path="/forgotpassword" element={<ForgotPassword/>} />
            <Route path="/forgot" element={<ForgotPassword/>} />
            <Route path="/profile" element={<Profile/>} />
    
          </Routes>
        </Router>
        
        <ToastContainer

       position="top-right"
       autoClose={1500}
       hideProgressBar
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="dark"
       
/>
      </GlobalStateProvider>
    </>
  );
}

export default App;

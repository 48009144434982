import React from "react";
import { useState, useEffect,useContext } from "react";
import { Navigate } from "react-router-dom";
import { getRandomIntInclusive } from "./Utils";
import { GlobalStateContext } from "../pages/GlobalStateContext"

const apiUrl = "HTTPS://siamapi.kiwijones.com"

const Points = ({ userId }) => {
  const {apiHostUrl, setApiHostUrl} = useContext(GlobalStateContext);
  const [data, setData] = useState();
  const [currentPoints, setCurrentPoints] = useState(0);
  const [logout, setLogout] = useState(false);
  const [loadingP, setLoadingP] = useState(true);

  useEffect(() => {


    let randomP = getRandomIntInclusive(1000, 3000);

    setTimeout(() => {

      console.log("userGuid: " + userId);

      const myHeaders = new Headers();
      myHeaders.append("Accept", "text/plain");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      var url = apiHostUrl + "/user/FindPoints/" + userId + " ";

      console.log(url);


      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setData(JSON.parse(result));

          var dd = JSON.parse(result);

          console.log(dd.points);

          setLoadingP(false);

          setCurrentPoints(dd.points);

          console.log(currentPoints);
        })
        .catch((error) => {
          setLoadingP(false);
          console.error(error);
        });
    }, randomP);
  }, []);

  function onLogout() {
    setLogout(true);
  }

  return (
    <>
      {logout ? (
        <Navigate to="/" />
      ) : (
        <>
         
          <br />

          {loadingP ? <img src="../Small_spinner.svg" /> : ""}

          <h3>Points</h3>
          <div>
            <br />

            <table className="table">
              <tr>
                <td>Points:</td>
                <td>{currentPoints}</td>
              </tr>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default Points;

import React, { useEffect, useState,useContext } from "react";
import Modal from "react-modal";
import QRCodeComponent from "./QRCodeComponent";
import { getRandomIntInclusive} from "./Utils"
import { GlobalStateContext } from "../pages/GlobalStateContext"

Modal.setAppElement("#root");

//const apiUrl = "HTTPS://siamapi.azurewebsites.net"
  
  const Coupons = ({ userId }) => {

  const {apiHostUrl, setApiHostUrl} = useContext(GlobalStateContext);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modalCode, setModalCode] = useState("");
  const [modalExpiry, setModalExpiry] = useState("");
  const [modalType, setModalType] = useState("");
  const [modalValue, setModalValue] = useState(0);
  const [showValue, setShowValue] = useState(false);
  const [loadingC,setLoadingC] = useState(true);

  const openModal = (id, code, expiry, couponType, value) => {
    setModalCode(code);
    setModalId(id);
    setModalExpiry(expiry);
    setModalType(couponType);

    setModalValue(value);

    if (value > 0) {
      setShowValue(true);
    }

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {

    let randomC = getRandomIntInclusive(1000,3000)

    setTimeout(() => {
    
    const myHeaders = new Headers();

    myHeaders.append("Accept", "text/plain");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var url = apiHostUrl +  "/user/ListCoupons/" + userId + "";

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setData(JSON.parse(result));

        setLoadingC(false)
      })
      .catch((error) => {
        console.error(error)
        setLoadingC(false)
    });
}, randomC);

  }, []);

  return (
    <>

      {loadingC ? <img src="../Small_spinner.svg" /> : ""}  

      <h3>Coupons</h3>

      <div>
        <table className="table ">
          <tr>
            <th>Type</th>
           
            <th>Expiry</th>
          </tr>

          {data.map((item) => (
            <tr>
              <td>
                <a className="aa"
                  href="#"
                  onClick={() =>
                    openModal(
                      item.id,
                      item.code,
                      item.expiry,
                      item.couponType,
                      item.value
                    )
                  }
                >
                 {item.couponType}
                </a>
              </td>
              

              <td>{item.expiry}</td>
            </tr>
          ))}
        </table>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <div className="container">
            <h3>{modalType}</h3>

            <table className="tableblack">
              <tr>
                <td>Code: </td>

                <td>{modalCode}</td>
              </tr>

              {!showValue ? (
                ""
              ) : (
                <tr>
                  <td>Value:</td>

                  <td>{modalValue}</td>
                </tr>
              )}

              <tr>
                <td>Expiry:</td>

                <td>{modalExpiry}</td>
              </tr>
            </table>

            <QRCodeComponent
              value={modalId}
              size={256}
              level="H"
              includeMargin={true}
            />

            <button onClick={closeModal}>X</button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Coupons;

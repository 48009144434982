import React from "react";
import { useState, useEffect } from "react";
import { getRandomIntInclusive } from "./Utils";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

const apiUrl = "HTTPS://siamapi.kiwijones.com"
const subscriptionUrl = "HTTPS://siamsubscription.kiwijones.com"


const Profile = ({ userId }) => {
  const [data, setData] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthMonth, setBirthMonth] = useState("0");
  const [isValid, setIsValid] = useState(true);
  const [formIsValid, setFormIsvalid] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [loadingP, setLoadingP] = useState(true);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [editPhoneNo, setEditPhoneNo] = useState(true);

  const handleBirthMonthChange = (event) => {

    setFormIsvalid(false);
  
    console.log("-------------------------------------------------");
    console.log(Number(event.target.value));

    if (Number(event.target.value) > 0) {
      setBirthMonth(event.target.value);
      console.log("true");

      setFormIsvalid(true);
    } else {
      setBirthMonth(event.target.value);

      setFormIsvalid(false);
      console.log("false");
    }

    console.log("--- form valid ---");
    console.log(formIsValid);
  };

  const handleChangeEmail = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setIsValid(validateEmail(inputValue));
  };

  const handleUpdateProfileData = (event) => {
    
    // console.log(event);
    // console.log(birthMonth);

    // console.log("update data");

    // console.log(firstName);
    // console.log(lastName);
    // console.log(email);
    // console.log(phone);
    // console.log(birthMonth);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      phoneNo: phone,
      email: email,
      birthMonth: birthMonth,
    });

    console.log(raw)

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      subscriptionUrl + "/UpdateUser/" + sessionStorage.getItem("userId"),
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {

        console.log(result)
        toast.success("Profile updated");
        setUpdateProfile(true);
        
    })
      .catch((error) => console.error(error));
  };

  const handleFirstNamechange = (event) => {
    console.log();
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    let randomP = getRandomIntInclusive(1000, 3000);
    //     console.log("userGuid: " + userId);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "text/plain");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var url =
      apiUrl + "/user/GetUserById/" +
      sessionStorage.getItem("userId") +
      " ";

    console.log(url);

    fetch(url, requestOptions)
      .then((response) => response.text())

      .then((result) => {
        //setData(JSON.parse(result));

        var userData = JSON.parse(result);

        console.log(userData);

        setEmail(userData.email);
        setPhone(userData.phoneNo);
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setBirthMonth(userData.birthMonth);
        
        if(userData.phoneNo.substring(0,3) == "000"){
          setEditPhoneNo(false)
        }

        setLoadingP(false);

        //   setCurrentPoints(dd.points);

        console.log(birthMonth);
      })
      .catch((error) => {
        
        setLoadingP(false);

        console.error(error);
      });
  }, []);

  return (
    <>
      {loadingP ? <img src="../Small_spinner.svg" /> : ""}


      {updateProfile ? <Navigate to="/mydetails" />: ""}

      <div>
        <div className="container_2">
          <h3>My Profile</h3>
          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              className="custom-input"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
              className="custom-input"
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              className="custom-input"
              type="email"
              id="email"
              value={email}
              onChange={handleChangeEmail}
              style={{ borderColor: isValid ? "" : "red" }}
              required
            />

            {!isValid && (
              <p style={{ color: "red" }}>Please enter a valid email address</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              className="custom-input"
              type="number"
              id="phone"
              disabled={editPhoneNo}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              required
            />

          </div>
          {userExists && (
            <div>
              <label className="warning">Account already exists</label>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="birthMonth">Birth Month:</label>
            <select
              className="custom-input"
              value={birthMonth}
              onChange={handleBirthMonthChange}
              id="birthMonth"
            >
              <option value="0">Choose not to disclose</option>

              <option value="1">January</option>
              <option value="2">Feburary</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>

          <button
            className="primary-button"
            type="submit"
            // disabled={formIsValid}
            onClick={handleUpdateProfileData}
          >
            Continue
            
          </button>
        </div>
      </div>
    </>
  );
};

export default Profile;

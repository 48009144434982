// Navbar.js
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import MySVG from "../src/assets/Logos/SiamThai_Main_Logo.svg";

import { GlobalStateContext  } from "./pages/GlobalStateContext";


const Navbar = ({ isAuthenticated, onLogout }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  
  const {isLoggedIn, setIsloggedIn} = useContext(GlobalStateContext)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMobileMenu = () => {
    setMenuOpen(false);
  };

  const redirectToMainPage = () => {
    closeMobileMenu();

    window.open(
      "https://siamthai.ie/",
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  return (
    <nav className="navbar">
      <Link className="logo" to="/" onClick={redirectToMainPage}>
        <img src={MySVG} alt="Description of the SVG" />
      </Link>
      <div className={`nav-items ${isMenuOpen ? "open" : ""}`}>
        <Link className="nav-link" to="/" onClick={closeMobileMenu}>
          Home
        </Link>

        <Link className="nav-link" to="/login">
          Check Points
        </Link>

        {/* <Link className="nav-link" to="/mydetails">
          My Details
        </Link> */}

          {/* only if the user is logged in will they see the profile menu option */}
        {isAuthenticated ? (
          <></>
        ) : (
          <>
            <Link className="nav-link" to="/register">
              Register
            </Link>
            </>
        )}
            <Link className="nav-link" to="/profile">
              Profile
            </Link>
      
      </div>
      <div className="hamburger" onClick={toggleMenu} onMouseOver={toggleMenu}>
        <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"} />
      </div>
    </nav>
  );
};

export default Navbar;

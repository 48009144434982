import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeComponent = ({ value, size = 128, level = 'L', includeMargin = false }) => {
    return (
        <QRCode
            value={value}
            size={size}
            level={level}
            includeMargin={includeMargin}
        />
    );
};

export default QRCodeComponent;
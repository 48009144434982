import './Home.css'
import HomeInfo from './HomeInfo';

import Footer from './footer';



const images = [
  'https://example.com/image1.jpg',
  'https://example.com/image2.jpg',
  'https://example.com/image3.jpg',
  // Add more image URLs as needed
];


const Home = () => {
  return (
    <>
    
    <div className='Slide'>

    
    {/* <BackGroundSlider/> */}
    
    <HomeInfo/>
{/* 
    <OpeningHours /> */}

    {/* <PhotoGallery images={images}/> */}
{/* 
    <Location/> */}



    {/* <ImageGalleryBase />

    <OrderButton/> */}

    <Footer />

    </div>

    </>
  )
}

export default Home